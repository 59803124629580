.main-app{
  background-color:white;
}
.main-container{
  background-color: whitesmoke;
  border-radius: 10px;
  margin: 1px auto;
  width: 100%;
}
.main-navbar{
  display: flex;
  justify-content: space-between;
  margin-bottom: 4%;
}
.main-navbar > a{
  text-decoration: none;
}
.main-navbar >a > img{
  width: 30%;
  margin-top: -3%;
}
.main-navbar > ul{
  display: flex;
  width: 100%;
}
.main-navbar > ul > li {
  list-style-type: none;
  padding: 3px;
}
.main-navbar > ul > li > a{
  padding: 8px;
  font-size: 0.99rem;
  text-decoration: none;
  background-color: whitesmoke;
  color: rgba(15, 118, 253, 0.956);
}
.nav-banner{
  width: 45%;
  background-color: rgba(15, 97, 56, 0.878);
  margin: 0 0 0 52%;
  border-radius: 7px;
  margin-top: -3.5%;
}
.nav-banner > h2{
  padding: 15px;
  color: white;
  text-align: center;
}
.banner-link{
  text-decoration: none;
}
.bar-links{
  list-style-type: none;
  display: flex;
  margin: 10px auto;
  background-color: rgba(15, 118, 253, 0.956);
  width: 98%;
}
.bar-links > li{
  margin: auto 1%;
  padding: 10px;
  border-right: 1px solid;
  color: white;
}
.bar-links > li > .first-level{
  width: 130%;
}
.bar-links > li > a{
  color: white;
  cursor: pointer;
  text-decoration: none;
}
.main-section{
  display: flex;
  background-color: rgb(11, 161, 161);
  margin: 1px auto;
  justify-content: space-evenly;
  border-radius: 5px;
  width: 100%;
}
.main-section > img{
  height: 370px;
  width: 55%;
}
.sub-section{
  width: 45%;
  text-align: center;
  padding: 26px;
}
.sub-section > h1{
  text-align: center;
  color: rgb(10, 10, 42);
  padding: 3px;
}
.sub-section > .first-paragraph, .second-paragraph{
  text-align: center;
  color: white;
  padding: 3px;
  margin: 0 0 6% 0;
}
.sub-section > .new-book{
  padding: 10px;
  color: black;
  background-color: rgba(240, 237, 198, 0.878);
  font-weight: bold;
}
.sub-section > .get-now{
  text-decoration: none;
  background-color:rgb(10, 10, 42);
  color: rgba(240, 237, 198, 0.878);
  padding: 11px;
  border-radius: 3px;
  font-weight: bold;
}
.cards-container {
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  flex-wrap: wrap;
}
.cards-container .card-child1 {
  width: 70%;
  box-sizing: border-box;
  padding: 10px;
  margin: 10px;
  display: flex;
  flex-direction: column;
}
.cards-container .card-child {
  width: 20%;
  box-sizing: border-box;
  padding: 10px;
  margin: 10px;
  display: flex;
  flex-direction: column;
}
.cards-container .card-child .card, .cards-container .card-child1 .card{
  width: 100%;
  box-sizing: border-box;
  padding: 10px;
  margin: auto 10px;
  flex-grow: 1;
}
.cards-container .card-child1 .card .card-title a{
  text-decoration: none;
  color: black;
  font-size: 1.5rem;
}
.cards-container .card-child .card .card-title a{
  text-decoration: none;
  color: black;
  font-weight: bold;
  font-size: 1.0rem;
}
.card-child1 .card .card-body{
  line-height: 28px;
  font-size: 0.98rem;
  font-family:Verdana, Geneva, Tahoma, sans-serif;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
.card-child .card .card-body{
  line-height: 28px;
  font-size: 0.88rem;
  font-family:Verdana, Geneva, Tahoma, sans-serif;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
.card-child .card .read-more-container, .card-child1 .card .read-more-container{
  margin-bottom: 10%;
}

.card-child .card .read-more-container .read-more  {
  font-size: 0.9rem;
}
.card-child .card .read-more-container .read-more, .card-child1 .card .read-more-container .read-more  {
  color:rgba(15, 118, 253, 0.956);
}
.card-child1 .card .read-more-container .read-more .read-more-fa{
  font-size: 0.9rem;
  margin-bottom: -0.2%;
  color:rgba(15, 118, 253, 0.956);
}
.card-child .card .read-more-container .read-more .read-more-fa{
  font-size: 0.7rem;
  margin-bottom: -0.5%;
  color:rgba(15, 118, 253, 0.956);
}
.card-child1 .card a img{
  width: 94%;
  height: 385px;
}
.card-child .card a img{
  width: 84%;
  height: 155px;
}
.card-child h3, .card-child1 h3{
  color: rgb(8, 67, 8);
  font-family: 'Times New Roman', Times, serif;
}
.card .card-title, .deadline{
  margin: 10px 0;
}
.card .deadline{
  color: rgb(73, 4, 4);
}
.search-form{
  text-align: center;
  margin-top: 2%;
  padding: 5px;
}
.search-form input[type="search"]{
  display: inline-block;
  margin: 3% auto;
  vertical-align: middle;
  width: 55%;
  height: 30px;
  border: 1px solid rgba(15, 97, 56, 0.878);
  border-radius: 5px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  transition: all .3s ease-in-out;
}
.search-form input[type="search"]::placeholder{
  font-family: 'Open Sans', sans-serif;
  font-size: 1.1em;   
}
.search-form input[type="submit"]{
  font-family: 'Open Sans', sans-serif;
  background-color: rgba(15, 97, 56, 0.878);
  font-size: 0.9em;
  width: 10%;
  height: 30px;
  margin: 2% 1%;
  display: inline-block;
  cursor: pointer;
  color: whitesmoke;
  border: 1px solid lightgray;
  border-radius: 5px;
}
.footer-main{
  background-color: black;
  color:white;
  display: flex;
  margin: 1% auto;
  width: 100%;
}
.footer-main > ul{
  margin: 1% auto;
}
.footer-main > ul > li{
  list-style-type: none;
  line-height: 20px;
  margin: 30px auto;
  font-size: 0.8rem;
}
.footer-main > ul > li > a{
  text-decoration: none;
  color:white;
}
.first-level {
  display: none;
  list-style-type: none;
  color: rgb(157, 56, 56);
  background-color: rgba(240, 237, 198, 0.878);
  padding-left: 0;
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 20px;
}
.first-level:hover{
  cursor: pointer;
}
li:hover .first-level {
  display: block;
  text-align: center;
}
.second-level {
  display: none;
  list-style-type: none;
  padding-left: 0;
  margin-top: 0;
  margin-bottom: 0;
}
.second-level:hover{
  cursor: pointer;
}
.second-level a:hover{
  color: black;
}
li:hover .second-level {
  display: block;
}
.third-level {
  display: none;
  list-style-type: none;
  padding-left: 0;
  margin-top: 0;
  margin-bottom: 0;
}
.third-level:hover{
  cursor: pointer;
}
.third-level a:hover{
  color:black;
}
li:hover .third-level {
  display: block;
  text-align: center;
}
/* Initially show the navigation links */
#navbarLinks {
  display: flex;
}

/* Hide the toggle button by default */
#toggleBtn {
  display: none;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 25px;
  margin: -14% 0 5% 80%;
}

/* Media query to show/hide elements based on screen size */
@media screen and (max-width: 768px) {
  #navbarLinks {
    display: none; /* Hide the navigation links on smaller screens */
  }
  .main-container .main-navbar a img{
    width: 32%;
  }
  #toggleBtn {
      display: block; 
      background: none;
      border: none;
      cursor: pointer;
      font-size: 1.6rem;
      margin: -17% 0 5% 80%;
  }

  /* Show the navigation links when the button is clicked */
  #toggleBtn.active + #navbarLinks {
      display: block;
  }
  
}

@media only screen and (max-width: 387px) and (min-width:200px) {
  .main-section > img{
    height: 485px;
  }
  .main-section > .sub-section{
    text-align: center;
    padding: 26px;
  }
  .main-section > .sub-section > h1{
    text-align: center;
    line-height: 25px;
    font-size: 0.8rem;
    padding: 5px;
  }
  .main-section > .sub-section > .new-book{
    width: 65%;
    padding: 10px;
    font-size: 0.5rem;
    font-weight: bold;
  }
  .main-section > .sub-section > .get-now{
    width: 5%;
    font-size: 0.6rem;
    text-decoration: none;
    padding: 11px;
    border-radius: 3px;
    font-weight: bold;
  }
  .main-section > .sub-section > .first-paragraph, .second-paragraph{
    font-size: 0.8rem;
  }
  .main-navbar {
    flex-direction: column;
    align-items: center;
  }
  .main-navbar > ul > li > a{
    padding: 5px;
    margin: 0 0 0 -20px;
    width: 100%;
    text-decoration: none;
    background-color: whitesmoke;
    color: rgba(15, 118, 253, 0.956);
    font-size: 0.8rem;
  }
  .main-navbar >a > img{
    width: 35%;
  }
  .main-navbar > ul{
    display: flex;
    width: 95%;
    margin-top: -4%;
  }
  .nav-banner{
    width: 40%;
    background-color: rgba(15, 97, 56, 0.878);
    margin: 0 0 0 52%;
    border-radius: 7px;
    margin-top: -3.5%;
    font-size: 0.6rem;
    line-height: 23px;
  }
  .main-container .bar-links{
    list-style-type: none;
    flex-direction: row;
    background-color: rgba(15, 118, 253, 0.956);
    font-size: 0.6rem;
  }
  .bar-links > li{
  margin: auto 1%;
  padding: 10px;
  border-right: 1px solid;
  color: white;
  }

.cards-container .card-child .card .card-title a, .cards-container .card-child1 .card .card-title a{
  text-decoration: none;
  font-weight: bold;
  color: black;
  font-size: 0.8rem;
}
.card-child .card .card-body{
  line-height: 22px;
  font-size: 0.8rem;
  font-family:Verdana, Geneva, Tahoma, sans-serif;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
.card-child .card .read-more-container .read-more {
  font-size: 0.8rem;
}
.card-child .card .read-more-container .read-more .read-more-fa {
  font-size: 0.7rem;
}
.card-child h3{
  color: rgb(8, 67, 8);
  font-family: 'Times New Roman', Times, serif;
  font-size: 0.9rem;
}
 .card-child .card .deadline{
  color: rgb(73, 4, 4);
  margin: 3px 0;
  font-size: 0.8rem;
}
.search-form{
  margin-top: 6%;
}
.search-form input[type="search"]{
  width: 75%;
}
.search-form input[type="submit"]{
  width: 16%;
  font-size: 0.65rem;
}
.search-form input[type="search"]::placeholder{
  font-size: 0.7rem;
}
.footer-main > ul > li{
  font-size: 0.6rem;
}
  
}

@media only screen and (max-width: 419px) and (min-width:388px) {
  .main-section > img{
    height: 485px;
    width: 45%;
  }
  .main-section > .sub-section{
    width: 50%;
    text-align: center;
    padding: 26px;
  }
  .main-section > .sub-section > h1{
    text-align: center;
    line-height: 35px;
    font-size: 1rem;
    padding: 5px;
  }
  .main-section > .sub-section > .new-book{
    padding: 10px;
    font-size: 0.5rem;
    font-weight: bold;
  }
  .main-section .sub-section > .get-now{
    font-size: 0.6rem;
    text-decoration: none;
    padding: 11px;
    border-radius: 3px;
    font-weight: bold;
  }
  .main-navbar {
    flex-direction: column;
    align-items: center;
  }
  .main-navbar > ul > li > a{
    padding: 5px;
    margin: 5px;
    width: 100%;
    text-decoration: none;
    background-color: whitesmoke;
    color: rgba(15, 118, 253, 0.956);
    font-size: 0.8rem;
  }
  .main-navbar >a > img{
    width: 25%;
  }
  .main-navbar > ul{
    display: flex;
    width: 95%;
    margin-top: -4%;
  }
  .nav-banner{
    width: 38%;
    background-color: rgba(15, 97, 56, 0.878);
    margin: 0 0 0 52%;
    border-radius: 7px;
    margin-top: -3.5%;
    font-size: 0.69rem;
    line-height: 23px;
  }
  .bar-links{
    list-style-type: none;
    flex-direction: row;
    background-color: rgba(15, 118, 253, 0.956);
    font-size: 0.8rem;
  }
  .bar-links > li{
  margin: auto 1%;
  padding: 10px;
  border-right: 1px solid;
  color: white;
  }
.cards-container .card-child .card .card-title a, .cards-container .card-child1 .card .card-title a{
  text-decoration: none;
  font-weight: bold;
  color: black;
  font-size: 0.8rem;
}
.card-child .card .card-body{
  line-height: 22px;
  font-size: 0.8rem;
  font-family:Verdana, Geneva, Tahoma, sans-serif;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
.card-child .card .read-more-container .read-more {
  font-size: 0.8rem;
}
.card-child .card .read-more-container .read-more .read-more-fa {
  font-size: 0.7rem;
}
 .card-child .card .deadline{
  color: rgb(73, 4, 4);
  margin: 3px 0;
  font-size: 0.8rem;
}
.search-form{
  margin-top: 6%;
}
.search-form input[type="search"]{
  width: 75%;
}
.search-form input[type="submit"]{
  width: 14%;
  font-size: 0.7rem;
}
.search-form input[type="search"]::placeholder{
  font-size: 0.75rem;
}
.footer-main > ul > li{
  font-size: 0.7rem;
}
  
}

@media(max-width:768px){
  .cards-container{
    display: block;
    width: 100%;
    margin: 1px auto;
    flex-wrap: wrap;
  }
  .cards-container .card-child, .cards-container > .card-child1{
    width: 90%;
    box-sizing: border-box;
    padding: 5px;
  }
  .cards-container > .card-child > .card{
    width: 100%;
    margin: 1px auto;
  }
  .cards-container .card-child .card .card-title a, .cards-container .card-child1 .card .card-title a{
    text-decoration: none;
    font-weight: bold;
    color: black;
    font-size: 1.0rem;
  }
  .cards-container .card-child .card .card-body, .cards-container .card-child1 .card .card-body{
    line-height: 30px;
    font-size: 0.9rem;
    font-family:Verdana, Geneva, Tahoma, sans-serif;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
  .cards-container .card-child .card .read-more-container .read-more {
    font-size: 1.0rem;
  }
  .cards-container .card-child .card .read-more-container .read-more .read-more-fa {
    font-size: 0.9rem;
  }
  .cards-container .card-child .card a img, .cards-container .card-child1 .card a img{
    width: 90%;
    height: 200px;
    margin: 3% 0 0 0;
  }
  .cards-container .card-child .card .deadline{
    color: rgb(73, 4, 4);
    margin: 3px 0;
    font-size: 1.0rem;
  }
  .main-section{
    width: 98%;
  }
  .main-section > .sub-section > .first-paragraph, .second-paragraph{
    font-size: 1.5rem;
  }
  .main-section > .sub-section > .get-now, .new-book{
    font-size: 1.2rem;
  }
  .main-navbar {
    flex-direction: column;
    align-items: center;
  }
  .main-navbar > ul > li > a{
    padding: 5px;
    margin: 5px;
    width: 100%;
    text-decoration: none;
    background-color: whitesmoke;
    color: rgba(15, 118, 253, 0.956);
    font-size: 0.9rem;
  }
  .main-navbar > ul{
    display: flex;
    width: 90%;
    margin: -5% 0 4% 0;
  }
  .nav-banner{
    font-size: 1rem;
  }
  .search-form{
    width: 100%;
    height: 30px;
    margin: 0 0 5% 0;
  }
  .search-form input[type="search"]{
    width: 70%;
    height: 45px;
  }
  .search-form input[type="submit"]{
    width: 16%;
    height: 50px;
    font-size: 1.4rem;
  }
  .search-form input[type="search"]::placeholder{
    font-family: 'Open Sans', sans-serif;
    font-size: 1.4rem;   
  }
  .footer-main > ul{
    margin-left: -2%;
  }
  .footer-main > ul > li > a{
    font-size: 1.15rem;
  }
}
@media only screen and (max-width: 442px) and (min-width:405px) {
  .main-section > img{
    height: 485px;
  }
  .main-section .sub-section{
    text-align: center;
    padding: 26px;
  }
  .main-section .sub-section > h1{
    text-align: center;
    line-height: 35px;
    font-size: 1rem;
    padding: 5px;
  }
  .main-section .sub-section > .new-book{
    padding: 10px;
    font-size: 0.6rem;
    background-color: rgba(240, 237, 198, 0.878);
    font-weight: bold;
  }
  .main-section .sub-section > .get-now{
    font-size: 0.6rem;
    text-decoration: none;
    color: white;
    padding: 11px;
    border-radius: 3px;
    font-weight: bold;
  }
  .main-section > .sub-section > .first-paragraph, .second-paragraph{
    font-size: 0.9rem;
  }
  .main-navbar {
    flex-direction: column;
    align-items: center;
  }
  .main-navbar > ul > li > a{
    padding: 5px;
    margin: 5px;
    width: 100%;
    text-decoration: none;
    background-color: whitesmoke;
    color: rgba(15, 118, 253, 0.956);
    font-size: 0.8rem;
  }
  .main-navbar >a > img{
    width: 25%;
  }
  .main-navbar > ul{
    display: flex;
    width: 95%;
    margin: -4% 0 3% -10%;
  }
  .nav-banner{
    width: 38%;
    background-color: rgba(15, 97, 56, 0.878);
    margin: 0 0 0 52%;
    border-radius: 7px;
    margin-top: -3.5%;
    font-size: 0.5rem;
    line-height: 23px;
  }
  .bar-links{
    list-style-type: none;
    flex-direction: row;
    background-color: rgba(15, 118, 253, 0.956);
    font-size: 0.6rem;
  }
  .bar-links > li{
  margin: auto 1%;
  padding: 10px;
  border-right: 1px solid;
  color: white;
  }

.card-child .card .card-body{
  line-height: 22px;
  font-size: 0.8rem;
  font-family:Verdana, Geneva, Tahoma, sans-serif;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}
.card-child .card .read-more-container .read-more {
  font-size: 0.8rem;
}
.card-child .card .read-more-container .read-more .read-more-fa {
  font-size: 0.7rem;
}
 .card-child .card .deadline{
  color: rgb(73, 4, 4);
  margin: 3px 0;
  font-size: 0.8rem;
}
.search-form{
  margin-top: 6%;
}
.search-form input[type="search"]{
  width: 75%;
  height: 35px;
}
.search-form input[type="submit"]{
  width: 14%;
  height: 35px;
  font-size: 0.7rem;
}
.search-form input[type="search"]::placeholder{
  font-size: 0.75rem;
}
.footer-main > ul > li > a{
  font-size: 0.63rem;
}
}

@media only screen and (max-width: 404px) and (min-width: 388px) {
  .main-section > img{
    height: 485px;
  }
  .main-section .sub-section{
    text-align: center;
    padding: 26px;
  }
  .main-section .sub-section > h1{
    text-align: center;
    line-height: 35px;
    font-size: 1rem;
    padding: 5px;
  }
  .main-section .sub-section > .new-book{
    padding: 10px;
    font-size: 0.6rem;
    background-color: rgba(240, 237, 198, 0.878);
    font-weight: bold;
  }
  .main-section .sub-section > .get-now{
    font-size: 0.6rem;
    text-decoration: none;
    color: white;
    padding: 11px;
    border-radius: 3px;
    font-weight: bold;
  }
  .main-section > .sub-section > .first-paragraph, .second-paragraph{
    font-size: 0.9rem;
  }
  .main-navbar {
    flex-direction: column;
    align-items: center;
  }
  .main-navbar > ul > li > a{
    padding: 5px;
    margin: 5px;
    width: 100%;
    text-decoration: none;
    background-color: whitesmoke;
    color: rgba(15, 118, 253, 0.956);
    font-size: 0.8rem;
  }
  .main-navbar >a > img{
    width: 25%;
  }
  .main-navbar > ul{
    display: flex;
    width: 95%;
    margin: -4% 0 3% -10%;
  }
  .nav-banner{
    width: 38%;
    background-color: rgba(15, 97, 56, 0.878);
    margin: 0 0 0 52%;
    border-radius: 7px;
    margin-top: -3.5%;
    font-size: 0.5rem;
    line-height: 23px;
  }
  .bar-links{
    list-style-type: none;
    flex-direction: row;
    background-color: rgba(15, 118, 253, 0.956);
    font-size: 0.6rem;
  }
  .bar-links > li{
  margin: auto 1%;
  padding: 10px;
  border-right: 1px solid;
  color: white;
  }

.card-child .card .card-body{
  line-height: 22px;
  font-size: 0.8rem;
  font-family:Verdana, Geneva, Tahoma, sans-serif;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}
.card-child .card .read-more-container .read-more {
  font-size: 0.8rem;
}
.card-child .card .read-more-container .read-more .read-more-fa {
  font-size: 0.7rem;
}
 .card-child .card .deadline{
  color: rgb(73, 4, 4);
  margin: 3px 0;
  font-size: 0.8rem;
}
.search-form{
  margin-top: 6%;
}
.search-form input[type="search"]{
  width: 75%;
  height: 35px;
}
.search-form input[type="submit"]{
  width: 14%;
  height: 35px;
  font-size: 0.7rem;
}
.search-form input[type="search"]::placeholder{
  font-size: 0.75rem;
}
.footer-main > ul > li > a{
  font-size: 0.63rem;
}
}

@media only screen and (max-width: 387px) and (min-width:365px) {
  .main-section > img{
    height: 465px;
  }
  .main-section .sub-section{
    text-align: center;
    padding: 26px;
  }
  .main-section .sub-section > h1{
    text-align: center;
    line-height: 30px;
    font-size: 0.8rem;
    padding: 5px;
  }
  .main-section .sub-section > .new-book{
    padding: 10px;
    font-size: 0.5rem;
    background-color: rgba(240, 237, 198, 0.878);
    font-weight: bold;
  }
  .main-section .sub-section > .get-now{
    font-size: 0.5rem;
    text-decoration: none;
    color: white;
    padding: 11px;
    border-radius: 3px;
    font-weight: bold;
  }
  .main-section > .sub-section > .first-paragraph, .second-paragraph{
    font-size: 0.7rem;
  }
  .main-navbar {
    flex-direction: column;
    align-items: center;
  }
  .main-navbar > ul > li > a{
    padding: 5px;
    margin: 5px;
    width: 100%;
    text-decoration: none;
    background-color: whitesmoke;
    color: rgba(15, 118, 253, 0.956);
    font-size: 0.75rem;
  }
  .main-navbar >a > img{
    width: 25%;
  }
  .main-navbar > ul{
    display: flex;
    width: 95%;
    margin: -4% 0 3% -10%;
  }
  .nav-banner{
    width: 36%;
    background-color: rgba(15, 97, 56, 0.878);
    margin: 0 0 0 52%;
    border-radius: 7px;
    margin-top: -3.5%;
    font-size: 0.4rem;
    line-height: 23px;
  }
  .bar-links{
    list-style-type: none;
    flex-direction: row;
    background-color: rgba(15, 118, 253, 0.956);
    font-size: 0.6rem;
  }
  .bar-links > li{
  margin: auto 1%;
  padding: 10px;
  border-right: 1px solid;
  color: white;
  }
.card-child .card .card-body{
  line-height: 22px;
  font-size: 0.8rem;
  font-family:Verdana, Geneva, Tahoma, sans-serif;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}
.card-child .card .read-more-container .read-more {
  font-size: 0.8rem;
}
.card-child .card .read-more-container .read-more .read-more-fa {
  font-size: 0.7rem;
}
 .card-child .card .deadline{
  color: rgb(73, 4, 4);
  margin: 3px 0;
  font-size: 0.8rem;
}
.search-form{
  margin-top: 6%;
}
.search-form input[type="search"]{
  width: 75%;
  height: 30px;
}
.search-form input[type="submit"]{
  width: 14%;
  height: 30px;
  font-size: 0.7rem;
}
.search-form input[type="search"]::placeholder{
  font-size: 0.75rem;
}
.footer-main > ul > li > a{
  font-size: 0.56rem;
}
}

@media only screen and (max-width: 364px) and (min-width:200px) {
  .main-section > img{
    height: 365px;
  }
  .main-section .sub-section{
    text-align: center;
    padding: 26px;
  }
  .main-section .sub-section > h1{
    text-align: center;
    line-height: 20px;
    font-size: 0.7rem;
    padding: 5px;
  }
  .main-section .sub-section > .new-book{
    padding: 10px;
    font-size: 0.5rem;
    background-color: rgba(240, 237, 198, 0.878);
    font-weight: bold;
  }
  .main-section .sub-section > .get-now{
    font-size: 0.5rem;
    text-decoration: none;
    color: white;
    padding: 11px;
    border-radius: 3px;
    font-weight: bold;
  }
  .main-section > .sub-section > .first-paragraph, .second-paragraph{
    font-size: 0.7rem;
  }
  .main-navbar {
    flex-direction: column;
    align-items: center;
  }
  .main-navbar > ul > li > a{
    padding: 5px;
    margin: 5px;
    width: 100%;
    text-decoration: none;
    background-color: whitesmoke;
    color: rgba(15, 118, 253, 0.956);
    font-size: 0.8rem;
  }
  .main-navbar >a > img{
    width: 25%;
  }
  .main-navbar > ul{
    display: flex;
    width: 95%;
    margin: -4% 0 3% -10%;
  }
  .nav-banner{
    width: 36%;
    background-color: rgba(15, 97, 56, 0.878);
    margin: 0 0 0 52%;
    border-radius: 7px;
    margin-top: -3.5%;
    font-size: 0.4rem;
    line-height: 23px;
  }
  .bar-links{
    list-style-type: none;
    flex-direction: row;
    background-color: rgba(15, 118, 253, 0.956);
    font-size: 0.6rem;
  }
  .bar-links > li{
  margin: auto 1%;
  padding: 10px;
  border-right: 1px solid;
  color: white;
  }
.card-child .card .card-body{
  line-height: 22px;
  font-size: 0.8rem;
  font-family:Verdana, Geneva, Tahoma, sans-serif;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}
.card-child .card .read-more-container .read-more {
  font-size: 0.8rem;
}
.card-child .card .read-more-container .read-more .read-more-fa {
  font-size: 0.7rem;
}
.card-child h3{
  color: rgb(8, 67, 8);
  font-family: 'Times New Roman', Times, serif;
  font-size: 0.85rem;
}
 .card-child .card .deadline{
  color: rgb(73, 4, 4);
  margin: 3px 0;
  font-size: 0.8rem;
}
.search-form{
  margin-top: 6%;
}
.search-form input[type="search"]{
  width: 75%;
  height: 30px;
}
.search-form input[type="submit"]{
  width: 15%;
  height: 30px;
  font-size: 0.65rem;
}
.search-form input[type="search"]::placeholder{
  font-size: 0.75rem;
}
.footer-main > ul > li > a{
  font-size: 0.6rem;
}
}

@media only screen and (max-width: 449px) and (min-width:443px) {
  .main-section > img{
    height: 485px;
  }
  .sub-section{
    text-align: center;
    padding: 26px;
  }
  .sub-section > h1{
    text-align: center;
    line-height: 35px;
    font-size: 0.6rem;
    padding: 5px;
  }
  .sub-section > .new-book{
    padding: 10px;
    font-size: 0.6rem;
    font-weight: bold;
  }
  .sub-section > .get-now{
    font-size: 0.6rem;
    text-decoration: none;
    color: white;
    padding: 11px;
    border-radius: 3px;
    font-weight: bold;
  }
  .main-navbar {
    flex-direction: column;
    align-items: center;
  }
  .main-navbar > ul > li > a{
    padding: 5px;
    margin: 5px;
    width: 100%;
    text-decoration: none;
    background-color: whitesmoke;
    color: rgba(15, 118, 253, 0.956);
    font-size: 0.85rem;
  }
  .main-navbar >a > img{
    width: 25%;
  }
  .main-navbar > ul{
    display: flex;
    width: 95%;
    margin-top: -4%;
  }
  .nav-banner{
    width: 38%;
    background-color: rgba(15, 97, 56, 0.878);
    margin: 0 0 0 52%;
    border-radius: 7px;
    margin-top: -3.5%;
    font-size: 0.69rem;
    line-height: 23px;
  }
  .bar-links{
    list-style-type: none;
    flex-direction: row;
    background-color: rgba(15, 118, 253, 0.956);
    font-size: 0.8rem;
  }
  .bar-links > li{
  margin: auto 1%;
  padding: 10px;
  border-right: 1px solid;
  color: white;
  }
.card-child .card .card-body{
  line-height: 22px;
  font-size: 0.8rem;
  font-family:Verdana, Geneva, Tahoma, sans-serif;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
.card-child .card .read-more-container .read-more {
  font-size: 0.8rem;
}
.card-child .card .read-more-container .read-more .read-more-fa {
  font-size: 0.7rem;
}
 .card-child .card .deadline{
  color: rgb(73, 4, 4);
  margin: 3px 0;
  font-size: 0.8rem;
}
.search-form{
  margin-top: 6%;
}
.search-form input[type="search"]{
  width: 75%;
}
.search-form input[type="submit"]{
  width: 14%;
  font-size: 0.7rem;
}
.search-form input[type="search"]::placeholder{
  font-size: 0.75rem;
}
.footer-main > ul > li{
  font-size: 0.8rem;
}
}

@media only screen and (max-width: 449px) and (min-width: 442px) {
  .main-section > img{
    height: 485px;
  }
  .sub-section{
    text-align: center;
    padding: 26px;
  }
  .sub-section > h1{
    text-align: center;
    line-height: 35px;
    font-size: 0.5rem;
    padding: 5px;
  }
  .sub-section > .new-book{
    padding: 10px;
    font-size: 0.6rem;
    font-weight: bold;
  }
  .sub-section > .get-now{
    font-size: 0.6rem;
    text-decoration: none;
    color: white;
    padding: 11px;
    border-radius: 3px;
    font-weight: bold;
  }
  .main-navbar {
    flex-direction: column;
    align-items: center;
  }
  .main-navbar > ul > li > a{
    padding: 5px;
    margin: 5px;
    width: 100%;
    text-decoration: none;
    background-color: whitesmoke;
    color: rgba(15, 118, 253, 0.956);
    font-size: 0.85rem;
  }
  .main-navbar >a > img{
    width: 25%;
  }
  .main-navbar > ul{
    display: flex;
    width: 95%;
    margin-top: -4%;
  }
  .nav-banner{
    width: 38%;
    background-color: rgba(15, 97, 56, 0.878);
    margin: 0 0 0 52%;
    border-radius: 7px;
    margin-top: -3.5%;
    font-size: 0.69rem;
    line-height: 23px;
  }
  .bar-links{
    list-style-type: none;
    flex-direction: row;
    background-color: rgba(15, 118, 253, 0.956);
    font-size: 0.8rem;
  }
  .bar-links > li{
  margin: auto 1%;
  padding: 10px;
  border-right: 1px solid;
  color: white;
  }
.card-child .card .card-body{
  line-height: 22px;
  font-size: 0.8rem;
  font-family:Verdana, Geneva, Tahoma, sans-serif;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
.card-child .card .read-more-container .read-more {
  font-size: 0.8rem;
}
.card-child .card .read-more-container .read-more .read-more-fa {
  font-size: 0.7rem;
}
 .card-child .card .deadline{
  color: rgb(73, 4, 4);
  margin: 3px 0;
  font-size: 0.8rem;
}
.search-form{
  margin-top: 6%;
}
.search-form input[type="search"]{
  width: 75%;
}
.search-form input[type="submit"]{
  width: 14%;
  font-size: 0.7rem;
}
.search-form input[type="search"]::placeholder{
  font-size: 0.75rem;
}
.footer-main > ul > li{
  font-size: 0.8rem;
}
}

@media only screen and (max-width: 767px) and (min-width: 450px) {
  .main-navbar {
    flex-direction: column;
    align-items: center;
  }
  .main-navbar > ul > li > a{
    padding: 5px;
    margin: 5px;
    width: 100%;
    text-decoration: none;
    background-color: whitesmoke;
    color: rgba(15, 118, 253, 0.956);
    font-size: 0.85rem;
  }
  .main-navbar >a > img{
    width: 25%;
  }
  .main-navbar > ul{
    display: flex;
    width: 95%;
    margin-top: -4%;
  }
  .nav-banner{
    width: 38%;
    background-color: rgba(15, 97, 56, 0.878);
    margin: 0 0 0 52%;
    border-radius: 7px;
    margin-top: -3.5%;
    font-size: 0.69rem;
    line-height: 23px;
  }
  .bar-links{
    list-style-type: none;
    flex-direction: row;
    background-color: rgba(15, 118, 253, 0.956);
    font-size: 0.8rem;
  }
  .bar-links > li{
  margin: auto 1%;
  padding: 10px;
  border-right: 1px solid;
  color: white;
  }
.card-child .card .card-body{
  line-height: 22px;
  font-size: 0.8rem;
  font-family:Verdana, Geneva, Tahoma, sans-serif;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
.card-child .card .read-more-container .read-more {
  font-size: 0.8rem;
}
.card-child .card .read-more-container .read-more .read-more-fa {
  font-size: 0.7rem;
}
 .card-child .card .deadline{
  color: rgb(73, 4, 4);
  margin: 3px 0;
  font-size: 0.8rem;
}
.main-section > img{
  height: 485px;
}
.sub-section{
  text-align: center;
  padding: 26px;
}
.sub-section > h1{
  text-align: center;
  line-height: 35px;
  font-size: 1.3rem;
  padding: 5px;
}
.sub-section > .new-book{
  padding: 10px;
  font-size: 0.8rem;
  font-weight: bold;
}
.sub-section > .get-now{
  font-size: 0.8rem;
  text-decoration: none;
  color: white;
  padding: 11px;
  border-radius: 3px;
  font-weight: bold;
}
.search-form{
  margin-top: 6%;
}
.search-form input[type="search"]{
  width: 75%;
}
.search-form input[type="submit"]{
  width: 14%;
  font-size: 0.7rem;
}
.search-form input[type="search"]::placeholder{
  font-size: 0.75rem;
}
}

@media only screen and (max-width: 810px) and (min-width: 706px) {
  .main-navbar {
    flex-direction: column;
    align-items: center;
  }
  .main-navbar > ul > li > a{
    padding: 5px;
    margin: 5px;
    width: 100%;
    text-decoration: none;
    background-color: whitesmoke;
    color: rgba(15, 118, 253, 0.956);
    font-size: 0.9rem;
  }
  .main-navbar >a > img{
    width: 25%;
  }
  .main-navbar > ul{
    display: flex;
    width: 95%;
    margin-top: -3%;
  }
  .nav-banner{
    width: 38%;
    background-color: rgba(15, 97, 56, 0.878);
    margin: 0 0 0 52%;
    border-radius: 7px;
    margin-top: -1.5%;
    font-size: 0.9rem;
    line-height: 33px;
  }
  .bar-links{
    list-style-type: none;
    flex-direction: row;
    background-color: rgba(15, 118, 253, 0.956);
    font-size: 1.1rem;
  }
  .bar-links > li{
  margin: auto 1%;
  padding: 10px;
  border-right: 1px solid;
  color: white;
  }
.cards-container{
  display: flex;
  width: 100%;
  flex-wrap: wrap;
}
.cards-container .card-child .card .card-title a, .cards-container .card-child1 .card .card-title a{
  text-decoration: none;
  color: black;
  font-size: 1.6rem;
}
.card-child .card .card-body{
  line-height: 34px;
  font-size: 1.5rem;
  font-family:Verdana, Geneva, Tahoma, sans-serif;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
.card-child .card .read-more-container .read-more {
  font-size: 1.7rem;
}
.card-child .card .read-more-container .read-more .read-more-fa {
  font-size: 1.5rem;
}
 .card-child .card .deadline{
  color: rgb(73, 4, 4);
  margin: 3px 0;
  font-size: 1.5rem;
}
.main-section{
  display: flex;
  margin: auto;
  justify-content: space-evenly;
  border-radius: 5px;
}
.main-section > img{
  height: 485px;
}
.sub-section{
  text-align: center;
  padding: 26px;
}
.sub-section > h1{
  text-align: center;
  line-height: 35px;
  font-size: 1.6rem;
  padding: 5px;
}
.sub-section > .new-book{
  padding: 10px;
  font-size: 1.2rem;
  font-weight: bold;
}
.sub-section > .get-now{
  font-size: 1.2rem;
  text-decoration: none;
  padding: 11px;
  border-radius: 3px;
  font-weight: bold;
}
.sub-section > .first-paragraph, .second-paragraph{
  font-size: 1.4rem;
}
.sub-section > .second-paragraph{
  margin-bottom: 8%;
}
.search-form{
  margin-top: 6%;
}
.search-form input[type="search"]{
  width: 75%;
  height: 48px;
}
.search-form input[type="submit"]{
  width: 15%;
  height: 50px;
  font-size: 1.5rem;
}
.search-form input[type="search"]::placeholder{
  font-size: 1.5rem;
}
.footer-main ul li{
  font-size: 1.4rem;
}
}

@media only screen and (max-width: 1280px) and (min-width: 811px) {
  .main-navbar {
    flex-direction: column;
    align-items: center;
  }
  .main-navbar > ul > li > a{
    padding: 5px;
    margin: 5px;
    width: 100%;
    text-decoration: none;
    background-color: whitesmoke;
    color: rgba(15, 118, 253, 0.956);
    font-size: 1.3rem;
  }
  .main-navbar >a > img{
    width: 25%;
  }
  .main-navbar > ul{
    display: flex;
    width: 95%;
    margin-top: -3%;
  }
  .nav-banner{
    width: 38%;
    background-color: rgba(15, 97, 56, 0.878);
    margin: 0 0 0 52%;
    border-radius: 7px;
    margin-top: -1.5%;
    font-size: 0.9rem;
    line-height: 33px;
  }
  .bar-links{
    list-style-type: none;
    flex-direction: row;
    background-color: rgba(15, 118, 253, 0.956);
    font-size: 1.1rem;
  }
  .bar-links > li{
  margin: auto 1%;
  padding: 10px;
  border-right: 1px solid;
  color: white;
  }
.cards-container{
  display: flex;
  width: 100%;
  flex-wrap: wrap;
}
.cards-container .card-child, .cards-container .card-child1{
  width: 90%;
  box-sizing: border-box;
  padding: 5px;
}
.cards-container .card-child .card{
  width: 100%;
}
.cards-container .card-child .card .card-title a, .cards-container .card-child1 .card .card-title a{
  text-decoration: none;
  color: black;
  font-size: 1.6rem;
}
.card-child .card .card-body{
  line-height: 34px;
  font-size: 1.5rem;
  font-family:Verdana, Geneva, Tahoma, sans-serif;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
.card-child .card .read-more-container .read-more {
  font-size: 1.7rem;
}
.card-child .card .read-more-container .read-more .read-more-fa {
  font-size: 1.5rem;
}
 .card-child .card .deadline{
  color: rgb(73, 4, 4);
  margin: 3px 0;
  font-size: 1.5rem;
}
.main-section{
  display: flex;
  margin: auto;
  justify-content: space-evenly;
  border-radius: 5px;
}
.main-section > img{
  height: 485px;
}
.sub-section{
  text-align: center;
  padding: 26px;
}
.sub-section > h1{
  text-align: center;
  line-height: 35px;
  font-size: 1.6rem;
  padding: 5px;
}
.sub-section > .new-book{
  padding: 10px;
  font-size: 1.2rem;
  font-weight: bold;
}
.sub-section > .get-now{
  font-size: 1.2rem;
  text-decoration: none;
  padding: 11px;
  border-radius: 3px;
  font-weight: bold;
}
.sub-section > .first-paragraph, .second-paragraph{
  font-size: 1.4rem;
}
.sub-section > .second-paragraph{
  margin-bottom: 8%;
}
.search-form{
  margin-top: 6%;
}
.search-form input[type="search"]{
  width: 75%;
  height: 48px;
}
.search-form input[type="submit"]{
  width: 15%;
  height: 50px;
  font-size: 1.5rem;
}
.search-form input[type="search"]::placeholder{
  font-size: 1.5rem;
}
.footer-main ul li{
  font-size: 1.4rem;
}
}

@media only screen and (max-width: 652px) and (min-width: 600px) {
  .main-navbar > ul > li > a{
    padding: 5px;
    margin: 5px;
    width: 100%;
    text-decoration: none;
    background-color: whitesmoke;
    color: rgba(15, 118, 253, 0.956);
    font-size: 0.9rem;
  }
.search-form input[type="submit"]{
  width: 12%;
  height: 40px;
  font-size: 1.0rem;
}
.search-form > input[type="search"]{
  height: 40px;
}
  .search-form input[type="search"]::placeholder{
    font-family: 'Open Sans', sans-serif;
    font-size: 0.9rem;   
}
}

@media only screen and (max-width: 599px) and (min-width: 547px) {
  .main-navbar > ul > li > a{
    padding: 5px;
    margin: 5px;
    width: 100%;
    text-decoration: none;
    background-color: whitesmoke;
    color: rgba(15, 118, 253, 0.956);
    font-size: 0.8rem;
  }
.search-form input[type="submit"]{
  width: 12%;
  height: 40px;
  font-size: 1.0rem;
}
.search-form > input[type="search"]{
  height: 40px;
}
  .search-form input[type="search"]::placeholder{
    font-family: 'Open Sans', sans-serif;
    font-size: 0.9rem;   
}
.main-section > .sub-section > .get-now, .main-section > .sub-section > .new-book{
  font-size: 0.8rem;
}
.main-section > .sub-section > .first-paragraph, .second-paragraph{
  font-size: 1.2rem;
}
.footer-main > ul > li > a{
  font-size:0.9rem
}
}

@media only screen and (max-width: 546px) and (min-width: 495px) {
  .main-navbar > ul > li > a{
    padding: 5px;
    margin: 5px;
    width: 100%;
    text-decoration: none;
    background-color: whitesmoke;
    color: rgba(15, 118, 253, 0.956);
    font-size: 0.8rem;
  }
.search-form input[type="submit"]{
  width: 12%;
  height: 40px;
  font-size: 0.9rem;
}
.search-form > input[type="search"]{
  height: 40px;
}
  .search-form input[type="search"]::placeholder{
    font-family: 'Open Sans', sans-serif;
    font-size: 0.9rem;   
}
.main-section > .sub-section > .get-now, .main-section > .sub-section > .new-book{
  font-size: 0.8rem;
}
.main-section > .sub-section > .first-paragraph, .second-paragraph{
  font-size: 1.2rem;
}
.footer-main > ul > li > a{
  font-size:0.9rem
}
}

@media only screen and (max-width: 494px) and (min-width: 443px) {
  .main-navbar > ul > li > a{
    padding: 5px;
    margin: 5px;
    width: 100%;
    text-decoration: none;
    background-color: whitesmoke;
    color: rgba(15, 118, 253, 0.956);
    font-size: 0.8rem;
  }
.search-form input[type="submit"]{
  width: 12%;
  height: 40px;
  font-size: 0.8rem;
}
.search-form > input[type="search"]{
  height: 35px;
}
  .search-form input[type="search"]::placeholder{
    font-family: 'Open Sans', sans-serif;
    font-size: 0.9rem;   
}
.main-section > .sub-section > h1{
  font-size:1.2rem;
}
.main-section > .sub-section > .get-now, .main-section > .sub-section > .new-book{
  font-size: 0.7rem;
}
.main-section > .sub-section > .first-paragraph, .second-paragraph{
  font-size: 1.1rem;
}
.footer-main > ul > li > a{
  font-size:0.7rem
}
}

@media only screen and (max-width: 705px) and (min-width: 653px) {
  .main-navbar {
    flex-direction: column;
    align-items: center;
  }
  .main-navbar > ul > li > a{
    padding: 5px;
    margin: 5px;
    width: 100%;
    text-decoration: none;
    background-color: whitesmoke;
    color: rgba(15, 118, 253, 0.956);
    font-size: 0.85rem;
  }
  .main-navbar >a > img{
    width: 25%;
  }
  .main-navbar > ul{
    display: flex;
    width: 95%;
    margin-top: -3%;
  }
  .nav-banner{
    width: 38%;
    background-color: rgba(15, 97, 56, 0.878);
    margin: 0 0 0 52%;
    border-radius: 7px;
    margin-top: -1.5%;
    font-size: 0.9rem;
    line-height: 33px;
  }
  .bar-links{
    list-style-type: none;
    flex-direction: row;
    background-color: rgba(15, 118, 253, 0.956);
    font-size: 1.1rem;
  }
  .bar-links > li{
  margin: auto 1%;
  padding: 10px;
  border-right: 1px solid;
  color: white;
  }
.cards-container .card-child .card, .cards-container .card-child1 .card{
  width: 100%;
}
.cards-container .card-child .card .card-title a, .cards-container .card-child1 .card .card-title a{
  text-decoration: none;
  font-weight: bold;
  color: black;
  font-size: 1.6rem;
}
.card-child .card .card-body{
  line-height: 34px;
  font-size: 1.5rem;
  font-family:Verdana, Geneva, Tahoma, sans-serif;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
.card-child .card .read-more-container .read-more {
  font-size: 1.7rem;
}
.card-child .card .read-more-container .read-more .read-more-fa {
  font-size: 1.5rem;
}
 .card-child .card .deadline{
  color: rgb(73, 4, 4);
  margin: 3px 0;
  font-size: 1.5rem;
}
.main-section > img{
  height: 485px;
}
.sub-section{
  text-align: center;
  padding: 26px;
}
.sub-section > h1{
  text-align: center;
  line-height: 40px;
  font-size: 1.4rem;
  padding: 5px;
}
.sub-section > .new-book{
  padding: 10px;
  font-size: 0.9rem;
  font-weight: bold;
}
.sub-section > .get-now{
  font-size: 0.9rem;
  text-decoration: none;
  padding: 11px;
  border-radius: 3px;
  font-weight: bold;
}
.main-section > .sub-section > .first-paragraph{
  font-size: 1.1rem;
}
.sub-section > .second-paragraph{
  font-size: 1.1rem;
  margin-bottom: 10%;
}
.search-form{
  margin-top: 6%;
}
.search-form input[type="search"]{
  width: 65%;
  height: 38px;
}
.search-form input[type="submit"]{
  width: 12%;
  height: 40px;
  font-size: 1.1rem;
}
.search-form input[type="search"]::placeholder{
  font-size: 1.2rem;
}
.footer-main ul li{
  font-size: 1.4rem;
}
}
@media(max-width: 560px){
  .main-section{
    display: block;
    border-radius: 2px;
    width: 98%;
  }
  .main-section > img{
    height: 250px;
    width: 100%;
  }
  .main-section > .sub-section{
    width: 100%;
    text-align: center;
    padding: 26px;
}
.main-section .sub-section > h1{
  text-align: center;
  padding: 3px;
  width: 90%;
}
.sub-section > .first-paragraph, .sub-section > .second-paragraph{
  text-align: center;
  padding: 3px;
  width: 90%;
}
.footer-main > ul > li > a{
  font-size: 0.7rem;
}
}

